import { fabStripe, farSquare } from '@quasar/extras/fontawesome-v6';
import { mdiFacebook, mdiGoogleAds, mdiHubspot } from '@quasar/extras/mdi-v7';

export default defineNuxtPlugin(({$config: {public: {hubspotClientId, ghlClientId, facebookAppId, calendlyClientId, redirectOrigin}}}) => ({ provide: { availblePossibleConnections: {
  facebook: { icon: mdiFacebook, href: `https://www.facebook.com/v16.0/dialog/oauth?response_type=code&client_id=${facebookAppId}&redirect_uri=${redirectOrigin}/install/facebook&auth_type=rerequest&config_id=221062116984466` },
  google: { icon: mdiGoogleAds },
  hubspot: { icon: mdiHubspot, href: `https://app.hubspot.com/oauth/authorize?client_id=${hubspotClientId}&redirect_uri=${redirectOrigin}/install/hubspot&scope=crm.objects.contacts.read crm.objects.deals.read conversations.read` },
  ghl: { icon: '', href: `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${redirectOrigin}/install/ghl&client_id=${ghlClientId}&scope=conversations/message.readonly conversations.readonly contacts.readonly campaigns.readonly opportunities.readonly users.readonly locations.readonly` },
  aloware: { icon: '' },
  calendly: { icon: '', href: `https://auth.calendly.com/oauth/authorize?client_id=${calendlyClientId}&response_type=code&redirect_uri=${redirectOrigin}/install/calendly` },
  twilio: { icon: '' },
  quickbooks: { icon: '' },
  stripe: { icon: fabStripe },
  square: { icon: farSquare },
  zapier: {},
  custom: {},
}}}))