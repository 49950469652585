import { FirebaseApp, initializeApp } from "firebase/app"
import { Auth, ParsedToken, User, getAuth, signInWithCustomToken } from "firebase/auth"
import { Firestore, initializeFirestore } from "firebase/firestore"
import { getFunctions } from 'firebase/functions'
export default defineNuxtPlugin(async (nuxtApp) => {
  
  
  const {public: {firebase}} = useRuntimeConfig()
  
  const app: FirebaseApp = nuxtApp.ssrContext?.event.context.app || initializeApp(firebase)
  const auth: Auth = nuxtApp.ssrContext?.event.context.auth || getAuth(app)
  
  const db: Firestore = initializeFirestore(app, {})
  const functions = getFunctions(app)
  
  // try {
  //   connectFunctionsEmulator(functions, 'localhost', 5001)
  //   connectFirestoreEmulator(db, 'localhost', 8080)
  // } catch (error) {
  //   console.error(error)
  // }

  const token = useState<string>('token')
  if (nuxtApp.ssrContext?.event.context.token) {
    token.value = nuxtApp.ssrContext?.event.context.token
  } else {
    auth.setPersistence({type: 'NONE'})
  }
  let skipFirstChange = false
  const user = ref<User | null | undefined>(nuxtApp.ssrContext?.event.context.user || null)
  const claims = ref<ParsedToken | null | undefined>(nuxtApp.ssrContext?.event.context.claims || null)
  if (nuxtApp.ssrContext === undefined) {  
    if (token.value) {
      const {user: _user} = await signInWithCustomToken(auth, token.value)
      user.value = _user
      claims.value = await _user.getIdTokenResult()
      skipFirstChange = true
    }
    auth.onIdTokenChanged(async usr => {
      if (usr) {
        const {claims: newClaims, expirationTime, token: _token} = await usr.getIdTokenResult()
        claims.value = newClaims
        user.value = usr
        token.value = _token
        if (skipFirstChange) {
          skipFirstChange = false
        } else {
          await $fetch('/api/session', {method: 'POST', body: {token: _token}})
        }
      } else if (usr === null) {
        claims.value = null
        user.value = null
        await $fetch('/api/session', {method: 'DELETE'})
        
      }
    })
  }
  return { provide: { functions, user, token, claims, app, auth, db, uid: computed(() => user.value?.uid) } }
})