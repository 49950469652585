import { ref, computed, useHead } from "#imports"
import { defineNuxtPlugin } from "#app"
import Quasar from "quasar/src/vue-plugin.js"
import Dialog from "quasar/src/plugins/Dialog.js"
import Notify from "quasar/src/plugins/Notify.js"
import Loading from "quasar/src/plugins/Loading.js"
import lang from "quasar/lang/en-US"
import iconSet from "quasar/icon-set/material-symbols-rounded"

export default defineNuxtPlugin((nuxt) => {

  const NuxtPlugin = {
    install({ onSSRHydrated }) {
      nuxt.hook("app:suspense:resolve", () => {
        onSSRHydrated.forEach(fn => fn())
      })
    }
  }


  nuxt.vueApp.use(Quasar, {
    lang,
    iconSet,
    plugins: {NuxtPlugin, Dialog,Notify,Loading},
    config: {"dark":false},
  })
})