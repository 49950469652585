<template>
  <q-page padding class="flex flex-center">
    <q-card v-if="$user" style="width: 5in;" >
      <q-item>
        <q-item-section>
          You are already logged in
        </q-item-section>
        <q-item-section side>
          <q-btn label="Log Out" @click="signOut($auth)" color="primary" icon="sym_r_logout"></q-btn>
        </q-item-section>
      </q-item>
    </q-card>
    <q-card style="width: 5in;" v-else>
      <q-form @submit.prevent="submit">
        <q-card-section class="flex column text-center" style="gap: 1em;">
          <q-btn :icon="mdiGoogle" label="Login with Google" @click="loginGoogle" color="blue"></q-btn>
          or
          <q-input v-model="email" label="Email" :error-message="error?.message" :error="!!error" filled :loading="methods === undefined" @blur="fetchMethods">
            <template #prepend>
              <q-icon name="sym_r_email" />
            </template>
          </q-input>
          <q-input v-model="password" label="Password" filled type="password" :error="!!errorPassword" :error-message="errorPassword?.message">
            <template #prepend>
              <q-icon name="sym_r_lock" />
            </template>
          </q-input>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn :disable="!methods?.length" label="Email Sign-In" @click="sendEmailSignIn" flat />
          <q-btn type="submit" :loading="methods === undefined" icon="sym_r_login" :label="enableLogin ? 'login' : enableRegister ? 'register' : 'error!'" :disable="!(enableLogin || enableRegister) || !!error" :class="{positive: enableLogin || enableRegister, info: methods === undefined, error: error || errorPassword}" />
          <!-- <q-btn v-if="enableLogin" flat size="md" label="Login" :class="{positive: enableLogin}" icon="sym_r_login" style="transition: .2s ease-in-out;" type="submit" />
          <q-btn v-if="enableRegister" flat size="md" label="Register" :disable="password.length === 0" :class="{positive: enableRegister}" icon="sym_r_login" style="transition: .2s ease-in-out;" type="submit" /> -->
        </q-card-actions>
        <q-item v-if="sent" class="info">
          Check the link in your email
        </q-item>
      </q-form>
    </q-card>
  </q-page>
</template>

<script setup lang="ts">
import { mdiGoogle } from '@quasar/extras/mdi-v7';
import { useDebounceFn } from '@vueuse/core';

import { FirebaseError } from 'firebase/app';
import { GoogleAuthProvider, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, sendSignInLinkToEmail, signInWithEmailAndPassword, signInWithRedirect, signOut } from 'firebase/auth';

const {$user, $auth} = useNuxtApp()

async function loginGoogle() {
  const provider = new GoogleAuthProvider()
  provider.addScope('profile');
  provider.addScope('email');
  if (!$auth) throw new Error('Auth Is Not Initilized')
  await signInWithRedirect($auth, provider)
  success()
}

async function sendEmailSignIn() {
  await sendSignInLinkToEmail($auth, email.value, {url: location.origin + '/login'})
  Notify.create({message: 'Sent! Check your email for a Sign-In link!', type: 'success'})
}

const email = ref('')
const password = ref('')
const methods = ref<null | undefined | string[]>(null)
const sent = ref(false)

const error = ref<FirebaseError>()
const errorPassword = ref<FirebaseError>()

const enableLogin = computed(() => (methods.value?.includes('password')))
const enableRegister = computed(() => (methods.value?.length === 0))

async function submit () {
  
  if (!$auth) throw new Error('Auth Is Not Initilized')
  try {
    Loading.show()
    if (enableLogin.value) {
      await signInWithEmailAndPassword($auth, email.value, password.value)
    } else if (enableRegister.value) {
      await createUserWithEmailAndPassword($auth, email.value, password.value)
    }
    success()
  } catch (error: FirebaseError) {
    errorPassword.value = error
  } finally {
    Loading.hide()
  }
}

const fetchMethodsDebounce = useDebounceFn(fetchMethods, 500)
watch(email, async () => {
  methods.value = undefined
  error.value = undefined
  errorPassword.value = undefined
  fetchMethodsDebounce()
})

async function fetchMethods () {
  if (!$auth) throw new Error('Auth Is Not Initilized')
  try {
    methods.value = await fetchSignInMethodsForEmail($auth, email.value)
    if (methods.value.length && !methods.value.includes('password')) {
      errorPassword.value = new FirebaseError('0', 'Password not enabled, login with: ' + methods.value.join())
    }
  } catch (err: FirebaseError) {
    error.value = err
    methods.value = null
  }
}

function success() {
  // navigateTo('/')
}
</script>