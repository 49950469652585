import { default as _91_46_46_46slug_93SAHsIwo42WMeta } from "/home/runner/work/www/www/pages/[...slug].vue?macro=true";
import { default as authorizeXCOTchAZmrMeta } from "/home/runner/work/www/www/pages/authorize.vue?macro=true";
import { default as _91id_93lsD0SUOqJqMeta } from "/home/runner/work/www/www/pages/connections/calendly/[id].vue?macro=true";
import { default as _91id_930YXjEFCxPeMeta } from "/home/runner/work/www/www/pages/connections/facebook/[id].vue?macro=true";
import { default as _91id_937DTCEiu7pEMeta } from "/home/runner/work/www/www/pages/connections/gohighlevel/[id].vue?macro=true";
import { default as _91id_93hBOsXKxqpbMeta } from "/home/runner/work/www/www/pages/connections/hubspot/[id].vue?macro=true";
import { default as indexhaQw5kGenSMeta } from "/home/runner/work/www/www/pages/connections/index.vue?macro=true";
import { default as connectionsZVpZTpO8tyMeta } from "/home/runner/work/www/www/pages/connections.vue?macro=true";
import { default as dashboardYN7elhUiwMMeta } from "/home/runner/work/www/www/pages/dashboard.vue?macro=true";
import { default as appointmentsAG1YXrDnNlMeta } from "/home/runner/work/www/www/pages/data/appointments.vue?macro=true";
import { default as dealsRzXIfGO6xpMeta } from "/home/runner/work/www/www/pages/data/deals.vue?macro=true";
import { default as interactionsvOuw2m2wEzMeta } from "/home/runner/work/www/www/pages/data/interactions.vue?macro=true";
import { default as leadskMSlMx1jTmMeta } from "/home/runner/work/www/www/pages/data/leads.vue?macro=true";
import { default as repse4635DkoWqMeta } from "/home/runner/work/www/www/pages/data/reps.vue?macro=true";
import { default as databEzoGIxE2iMeta } from "/home/runner/work/www/www/pages/data.vue?macro=true";
import { default as _91id_93ac6iGbER6oMeta } from "/home/runner/work/www/www/pages/import/[id].vue?macro=true";
import { default as indexMgbeRSULgUMeta } from "/home/runner/work/www/www/pages/index.vue?macro=true";
import { default as login0vLN8WEvQiMeta } from "/home/runner/work/www/www/pages/login.vue?macro=true";
import { default as markdqMzagHqdmMeta } from "/home/runner/work/www/www/pages/mark.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93SAHsIwo42WMeta?.name ?? "slug",
    path: _91_46_46_46slug_93SAHsIwo42WMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93SAHsIwo42WMeta || {},
    alias: _91_46_46_46slug_93SAHsIwo42WMeta?.alias || [],
    redirect: _91_46_46_46slug_93SAHsIwo42WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: authorizeXCOTchAZmrMeta?.name ?? "authorize",
    path: authorizeXCOTchAZmrMeta?.path ?? "/authorize",
    meta: authorizeXCOTchAZmrMeta || {},
    alias: authorizeXCOTchAZmrMeta?.alias || [],
    redirect: authorizeXCOTchAZmrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/authorize.vue").then(m => m.default || m)
  },
  {
    path: connectionsZVpZTpO8tyMeta?.path ?? "/connections",
    children: [
  {
    name: _91id_93lsD0SUOqJqMeta?.name ?? "connections-calendly-id",
    path: _91id_93lsD0SUOqJqMeta?.path ?? "calendly/:id()",
    meta: _91id_93lsD0SUOqJqMeta || {},
    alias: _91id_93lsD0SUOqJqMeta?.alias || [],
    redirect: _91id_93lsD0SUOqJqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/connections/calendly/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_930YXjEFCxPeMeta?.name ?? "connections-facebook-id",
    path: _91id_930YXjEFCxPeMeta?.path ?? "facebook/:id()",
    meta: _91id_930YXjEFCxPeMeta || {},
    alias: _91id_930YXjEFCxPeMeta?.alias || [],
    redirect: _91id_930YXjEFCxPeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/connections/facebook/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_937DTCEiu7pEMeta?.name ?? "connections-gohighlevel-id",
    path: _91id_937DTCEiu7pEMeta?.path ?? "gohighlevel/:id()",
    meta: _91id_937DTCEiu7pEMeta || {},
    alias: _91id_937DTCEiu7pEMeta?.alias || [],
    redirect: _91id_937DTCEiu7pEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/connections/gohighlevel/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93hBOsXKxqpbMeta?.name ?? "connections-hubspot-id",
    path: _91id_93hBOsXKxqpbMeta?.path ?? "hubspot/:id()",
    meta: _91id_93hBOsXKxqpbMeta || {},
    alias: _91id_93hBOsXKxqpbMeta?.alias || [],
    redirect: _91id_93hBOsXKxqpbMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/connections/hubspot/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhaQw5kGenSMeta?.name ?? "connections",
    path: indexhaQw5kGenSMeta?.path ?? "",
    meta: indexhaQw5kGenSMeta || {},
    alias: indexhaQw5kGenSMeta?.alias || [],
    redirect: indexhaQw5kGenSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/connections/index.vue").then(m => m.default || m)
  }
],
    name: connectionsZVpZTpO8tyMeta?.name ?? undefined,
    meta: connectionsZVpZTpO8tyMeta || {},
    alias: connectionsZVpZTpO8tyMeta?.alias || [],
    redirect: connectionsZVpZTpO8tyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/connections.vue").then(m => m.default || m)
  },
  {
    name: dashboardYN7elhUiwMMeta?.name ?? "dashboard",
    path: dashboardYN7elhUiwMMeta?.path ?? "/dashboard",
    meta: dashboardYN7elhUiwMMeta || {},
    alias: dashboardYN7elhUiwMMeta?.alias || [],
    redirect: dashboardYN7elhUiwMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: databEzoGIxE2iMeta?.name ?? "data",
    path: databEzoGIxE2iMeta?.path ?? "/data",
    children: [
  {
    name: appointmentsAG1YXrDnNlMeta?.name ?? "data-appointments",
    path: appointmentsAG1YXrDnNlMeta?.path ?? "appointments",
    meta: appointmentsAG1YXrDnNlMeta || {},
    alias: appointmentsAG1YXrDnNlMeta?.alias || [],
    redirect: appointmentsAG1YXrDnNlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/data/appointments.vue").then(m => m.default || m)
  },
  {
    name: dealsRzXIfGO6xpMeta?.name ?? "data-deals",
    path: dealsRzXIfGO6xpMeta?.path ?? "deals",
    meta: dealsRzXIfGO6xpMeta || {},
    alias: dealsRzXIfGO6xpMeta?.alias || [],
    redirect: dealsRzXIfGO6xpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/data/deals.vue").then(m => m.default || m)
  },
  {
    name: interactionsvOuw2m2wEzMeta?.name ?? "data-interactions",
    path: interactionsvOuw2m2wEzMeta?.path ?? "interactions",
    meta: interactionsvOuw2m2wEzMeta || {},
    alias: interactionsvOuw2m2wEzMeta?.alias || [],
    redirect: interactionsvOuw2m2wEzMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/data/interactions.vue").then(m => m.default || m)
  },
  {
    name: leadskMSlMx1jTmMeta?.name ?? "data-leads",
    path: leadskMSlMx1jTmMeta?.path ?? "leads",
    meta: leadskMSlMx1jTmMeta || {},
    alias: leadskMSlMx1jTmMeta?.alias || [],
    redirect: leadskMSlMx1jTmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/data/leads.vue").then(m => m.default || m)
  },
  {
    name: repse4635DkoWqMeta?.name ?? "data-reps",
    path: repse4635DkoWqMeta?.path ?? "reps",
    meta: repse4635DkoWqMeta || {},
    alias: repse4635DkoWqMeta?.alias || [],
    redirect: repse4635DkoWqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/data/reps.vue").then(m => m.default || m)
  }
],
    meta: databEzoGIxE2iMeta || {},
    alias: databEzoGIxE2iMeta?.alias || [],
    redirect: databEzoGIxE2iMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/data.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ac6iGbER6oMeta?.name ?? "import-id",
    path: _91id_93ac6iGbER6oMeta?.path ?? "/import/:id()",
    meta: _91id_93ac6iGbER6oMeta || {},
    alias: _91id_93ac6iGbER6oMeta?.alias || [],
    redirect: _91id_93ac6iGbER6oMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/import/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMgbeRSULgUMeta?.name ?? "index",
    path: indexMgbeRSULgUMeta?.path ?? "/",
    meta: indexMgbeRSULgUMeta || {},
    alias: indexMgbeRSULgUMeta?.alias || [],
    redirect: indexMgbeRSULgUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login0vLN8WEvQiMeta?.name ?? "login",
    path: login0vLN8WEvQiMeta?.path ?? "/login",
    meta: login0vLN8WEvQiMeta || {},
    alias: login0vLN8WEvQiMeta?.alias || [],
    redirect: login0vLN8WEvQiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/login.vue").then(m => m.default || m)
  },
  {
    name: markdqMzagHqdmMeta?.name ?? "mark",
    path: markdqMzagHqdmMeta?.path ?? "/mark",
    meta: markdqMzagHqdmMeta || {},
    alias: markdqMzagHqdmMeta?.alias || [],
    redirect: markdqMzagHqdmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/www/www/pages/mark.vue").then(m => m.default || m)
  }
]