import revive_payload_client_f0zZE1c9iH from "/home/runner/work/www/www/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.17.6_sass@1.66.1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_ei63uGQtm1 from "/home/runner/work/www/www/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.17.6_sass@1.66.1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/www/www/.nuxt/components.plugin.mjs";
import unhead_zSD5qf0Q3n from "/home/runner/work/www/www/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.17.6_sass@1.66.1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_bmnzvTzzHi from "/home/runner/work/www/www/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.17.6_sass@1.66.1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import quasar_plugin_G9rpXFQbul from "/home/runner/work/www/www/.nuxt/quasar-plugin.mjs";
import provide_dLCHepHioA from "/home/runner/work/www/www/node_modules/.pnpm/nuxt-quasar-ui@2.0.5_@quasar+extras@1.16.5_quasar@2.12.5/node_modules/nuxt-quasar-ui/dist/runtime/provide.mjs";
import view_transitions_client_f642UXiGaG from "/home/runner/work/www/www/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.17.6_sass@1.66.1/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_J3P90sM2Z8 from "/home/runner/work/www/www/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.17.6_sass@1.66.1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import connection_ts_jbyT2QRk84 from "/home/runner/work/www/www/plugins/connection.ts.ts";
import echarts_HOs5L9sCmP from "/home/runner/work/www/www/plugins/echarts.ts";
import firebase_Wjz9XrZo01 from "/home/runner/work/www/www/plugins/firebase.ts";
export default [
  revive_payload_client_f0zZE1c9iH,
  router_ei63uGQtm1,
  components_plugin_KR1HBZs4kY,
  unhead_zSD5qf0Q3n,
  prefetch_client_bmnzvTzzHi,
  quasar_plugin_G9rpXFQbul,
  provide_dLCHepHioA,
  view_transitions_client_f642UXiGaG,
  chunk_reload_client_J3P90sM2Z8,
  connection_ts_jbyT2QRk84,
  echarts_HOs5L9sCmP,
  firebase_Wjz9XrZo01
]