<template>
  <q-layout view="lHh lpR fFf">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100" preserveAspectRatio="none" viewBox="0 0 1000 1000"><defs><filter id="b" x="-500" y="-500" width="2000" height="2000" filterUnits="userSpaceOnUse"><feGaussianBlur in="SourceGraphic" stdDeviation="100"/></filter><filter id="a" x="-500" y="-500" width="2000" height="2000" filterUnits="userSpaceOnUse">&gt;<feFlood flood-color="#fff" result="neutral-gray"/><feTurbulence type="fractalNoise" baseFrequency="2.5" numOctaves="100" stitchTiles="stitch" result="noise"/><feColorMatrix in="noise" type="saturate" values="0" result="destaturatedNoise"/><feComponentTransfer in="desaturatedNoise" result="theNoise"><feFuncA type="table" tableValues="0 0 0.1 0"/></feComponentTransfer><feBlend in="SourceGraphic" in2="theNoise" mode="soft-light" result="noisy-image"/></filter><radialGradient id="c" cx="50%" cy="50%" r="50%" fx="54%" fy="49%"><stop offset="0%" stop-color="#0053db"/><stop offset="100%" stop-color="rgba(81,0,219,0.2)"/></radialGradient></defs><rect width="100%" height="100%" fill="#f9f9f9"/><g filter="url(#a)"><g filter="url(#b)"><svg xmlns="http://www.w3.org/2000/svg" width="700" height="700" viewBox="0 0 500 500" transform="translate(-243.067 -101.87)"><path fill="url(#c)" d="M405 345.5q-45 95.5-153 92T92.5 342q-51.5-92-3-189.5t153.5-85Q348 80 399 165t6 180.5Z"/></svg></g></g></svg> -->
    <q-header class="bg-black text-white">
      <q-toolbar class="flex flex-center justify-between text-white" style="padding-inline: 2em;">
        <div class="flex flex-center">
          <!-- <q-btn dense flat round icon="sym_r_menu" @click="leftDrawerOpen = !leftDrawerOpen" v-if="user && !$q.screen.gt.sm" /> -->
          <div class="inconsolata">leadspeed</div>
        </div>
        <q-tabs inline-label narrow-indicator v-if="$user" dense shrink class="desktop-only">
          <q-route-tab v-for="{to, label, icon} in routes"  :to="to" :label="$q.screen.lt.sm ? undefined : label" :icon="icon" active-class="text-gum-pink" />
        </q-tabs>
        <div>
          <q-btn :icon="$user ? 'sym_r_logout' : 'sym_r_login'" flat :label="$q.screen.lt.md ? undefined : $user ? 'logout' : 'login'" :to="$user ? undefined : '/login'" @click="$user ? logout() : undefined" :loading="$user === undefined"></q-btn>
          <q-btn flat @click="$q.dark.toggle()" :icon="$q.dark.isActive ? 'sym_r_light_mode' : 'sym_r_dark_mode'"></q-btn>
        </div>
      </q-toolbar>
    </q-header>

    <!-- <q-drawer v-model="leftDrawerOpen" side="left" class="inverse-surface" :mini="false" @mouseover="miniState = false" @mouseout="miniState = true" :width="200" :breakpoint="500" style="padding: 1em;">
      <div class="inconsolata flex flex-left" >leadspeed</div>
      <q-list padding class="text-grey-7">
        <q-item clickable v-ripple v-for="{to, label, icon} in routes"  :to="to" active-class="text-grey-1">
          <q-item-section avatar>
              <q-icon :name="icon" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ label }}</q-item-label>
            </q-item-section>
        </q-item>
      </q-list>
    </q-drawer> -->
    <q-footer elevated class="bg-black text-white mobile-only">
      <q-tabs narrow-indicator v-if="$user" dense shrink>
          <q-route-tab v-for="{to, label, icon} in routes"  :to="to" :label="$q.screen.lt.sm ? undefined : label" :icon="icon" active-class="text-gum-pink" ></q-route-tab>
        </q-tabs>
    </q-footer>
    <q-page-container>
      <login v-if="$user === null"/>
      <nuxt-page v-else-if="$user !== undefined" />
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
import { signOut } from 'firebase/auth';
import login from './pages/login.vue';
const router = useRouter()
const route = useRoute()
const leftDrawerOpen = ref(true)
const miniState = ref(false)

const {$auth, $user} = useNuxtApp()
async function logout() {
  return await signOut($auth)
}
// we don't need this watcher on server
onMounted(() => {
  watch($user, (user, prevUser) => {
    if (prevUser && !user) {
      router.push('/login')
    } else if (user && typeof route.query.redirect === 'string') {
      router.push(route.query.redirect)
    }
  })
})

const routes = [
  {label: 'stats', to: '/dashboard', icon: 'sym_r_monitoring'},
  {label: 'data', to: '/data', icon: 'sym_r_database'},
  {label: 'apps', to: '/connections', icon: 'sym_r_link'},
  {label: 'mark', to: '/mark', icon: 'sym_r_edit_note'},

]

useSeoMeta({
  title: 'leadspeed',
  description: 'Your KPI Tracking soloution',
  ogTitle: '[og:title]',
  ogDescription: '[og:description]',
  ogImage: '[og:image]',
  ogUrl: '[og:url]',
  twitterTitle: '[twitter:title]',
  twitterDescription: '[twitter:description]',
  twitterImage: '[twitter:image]',
  twitterCard: 'summary'
})

useHead({
  htmlAttrs: {
    lang: 'en'
  },
  link: [
    {
      rel: 'icon',
      type: 'image/ico',
      href: '/favicon.ico'
    }
  ]
})
</script>
